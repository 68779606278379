<template>
  <div>
    <base-content-management 
      title="Text" 
      contentType="text" 
      :isStaticTitle="true" 
      :enableAdd="false" 
      :enableDelete="false" 
      :useCover="false"
      :useCoverContent="false"
      :hideTitleContent="true"
      :hideDescriptionContent="false"
    >
    </base-content-management>
  </div>
</template>

<script>
import BaseContentManagement from './BaseContentManagement.vue'

export default {
  title () {
    return `Meta Text Management`
  },
  components: {
    BaseContentManagement,
  },
}
</script>

<style>

</style>
